import React, { Fragment, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonStyles,
  ButtonTypes,
  Input,
} from "icr-shared-components/dist";
import { useHistory } from "react-router";
import { RootState } from "../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action } from "../../actions";
import {
  updateProfileLoginData,
  setNewPhoneNumber,
} from "../../actions/profile";
import { connect } from "react-redux";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../utils/useValidateFrom";
import { getRouteByLanguage, DefaultRouteNames } from "../../routes";

interface Props {
  updateProfileLoginData: any;
  setNewPhoneNumber: any;
}

const Phone = ({ updateProfileLoginData, setNewPhoneNumber }: Props) => {
  const [phone, setPhone] = useState("");
  const [isBlurred, setIsBlurred] = useState(false);

  const history = useHistory();
  const validation = useValidateFrom("profile", formConfig, { phone });

  const handleSubmit = async (e) => {
    const payload = {
      mobileNumber: phone,
    };

    const response = await updateProfileLoginData(payload);
    setNewPhoneNumber(phone);

    history.push(
      getRouteByLanguage(DefaultRouteNames.PROFILEPHONEVERIFICATION)
    );
  };

  return (
    <Fragment>
      <div className="profile-modal-text">
        Geben Sie Ihre neue Mobiltelefonnummer ein. Lassen Sie sich dann einen
        Bestätigungscode per SMS senden, um die Änderung abzuschliessen.
      </div>
      <div className="row">
        <div className="col-md-5 col-xs-12">
          <Input
            type="text"
            name="email"
            value={phone}
            onChange={(name, value) => setPhone(value)}
            placeholder="+41 7x xxx xx xx"
            inputWrapperClass="mb-48"
            onBlur={() => setIsBlurred(true)}
            validationMessageConfig={
              (isBlurred ||
                validation?.phone?.validation?.type === "success") && {
                config: validation.phone.validation,
                visible: true,
              }
            }
          >
            Ihre neue Mobiltelefonnummer
          </Input>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={() =>
            history.push(getRouteByLanguage(DefaultRouteNames.PROFILE))
          }
        >
          abbrechen
        </Button>

        <Button
          isDisabled={validation?.phone?.validation?.type !== "success"}
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={handleSubmit}
        >
          SMS CODE SENDEN
        </Button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  profileLoginData: state.profile.profileLoginData,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  updateProfileLoginData: bindActionCreators(updateProfileLoginData, dispatch),
  setNewPhoneNumber: bindActionCreators(setNewPhoneNumber, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Phone);
