import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../../../reducers";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components";
import { Action, setLoading } from "../../../actions";
import { registrationCode } from "../../../actions/register";
import { loginEmail } from "../../../actions/login";
import { bindActionCreators, Dispatch } from "redux";
import { useTranslation } from "react-i18next";
import { Languages } from "../../../constants/enums";
import { getTenantFromUrl } from "../../../utils";
import { toaster } from "../../../utils/toaster";
import { getRouteByLanguage, DefaultRouteNames } from "../../../routes";

const defaultTenant = process.env.REACT_APP_DEFAULT_TENANT || "PKSO";

interface Props {
  email: any;
  setLoading: any;
  loginEmail: any;
  registrationCode: any;
}

const Link: FunctionComponent<Props> = ({
  email,
  setLoading,
  loginEmail,
  registrationCode,
}) => {
  const history = useHistory();
  const { i18n, t: translate } = useTranslation(["auth"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });

  // useEffect(() => {
  //   if (!email) {
  //     history.push("/login");
  //   }
  // }, []);

  const next = async () => {
    const language = Languages[i18n.language];
    let tenant =
      getTenantFromUrl().replace("/", "") ||
      window.localStorage.getItem("tenantName");
    setLoading(true);
    try {
      await loginEmail({
        emailAddress: email,
        urlCallback:
          window.location.origin +
          `${getTenantFromUrl()}/${
            i18n.language
          }/login?sessionid=[token]&emailcode=[emailcode]`,
        tenant: tenant || defaultTenant,
        language: language,
      });
    } catch (err) {
      await registrationCode({
        emailAddress: email,
        tenant: tenant || defaultTenant,
        urlCallback:
          window.location.origin +
          `${getRouteByLanguage(
            DefaultRouteNames.REGISTER
          )}?sessionid=[token]&emailcode=[emailcode]`,
        language: language,
      });
    }
    setLoading(false);
    toaster({
      message: "The login link was sent to your email!",
      type: "success",
    });
  };

  return (
    <React.Fragment>
      <h1 className="mb-40">{t("login.link.title", "E-Mail Login")}</h1>

      <p className="mb-24">
        {t(
          "login.link.sentLinkInformation",
          "Wir haben Ihnen einen Login-Link an die folgende. E-Mail-Adresse gesendet."
        )}
      </p>

      <h3 className="mb-24">{email || "example@email.com"}</h3>

      <p className="mb-48">
        {t(
          "login.link.pleaseOpenEmail",
          "Öffnen Sie die E-Mail und klicken Sie auf den Login-Link. Sie werden dann automatisch eingeloggt."
        )}
      </p>

      <h4 className="mb-24">
        {t("login.link.didNotGetLink", "Keinen Login-Link erhalten?")}
      </h4>

      <p className="mb-24">
        {t(
          "login.link.checkEmailAndSpam",
          "Stimmt die E-Mail-Adresse? Wenn ja, prüfen Sie bitte den Spam-Ordner Ihres Mailprogramms. Sie können sich den Login-Link erneut zustellen lassen."
        )}
      </p>

      <Button
        id="loginResendButton"
        onClick={() => next()}
        type={ButtonTypes.Text}
        color={ButtonColors.Action}
        className="login-button-text"
      >
        {t("login.link.resendButton.label", "Login Link empfangen")}
      </Button>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  email: state.app.email,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoading: (value) => dispatch(setLoading(value)),
  loginEmail: bindActionCreators(loginEmail, dispatch),
  registrationCode: bindActionCreators(registrationCode, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Link);
