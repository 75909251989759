import { privateApi as API } from "../utils/api";
import { SET_FAQ } from "../constants/actions";
import refreshToken from "./refreshToken";

export const getFAQ = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response = await API.get("faq", {
        params: payload,
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      dispatch({ type: SET_FAQ, payload: response.data.data });
      await refreshToken(null);
      return response;
    } catch (err) {
      return err;
    }
  };
};
