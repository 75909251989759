import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
} from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { checkAuthStatus } from "../../utils/react";
import { RootState } from "../../reducers";
import { connect } from "react-redux";

interface Props {
  path: string;
  sessionId: string;
  children: ReactNode;
  exact?: boolean;
  token: string;
}

const AuthRoute: React.FC<Props> = ({
  path,
  sessionId,
  children,
  exact,
  token,
}) => {
  const location = useLocation();
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (checkAuthStatus(sessionId, token, location.pathname)) {
          const childrenWithProps = Children.map(children, (child) => {
            // Checking isValidElement is the safe way and avoids a TS error too.
            if (isValidElement(child)) {
              return cloneElement(child, { ...props });
            }

            return child;
          });

          return <div>{childrenWithProps}</div>;
        } else {
          const location = props.location.pathname.split("/");

          return (
            <Redirect
              to={
                location.length > 3
                  ? `/${location[1]}/${location[2]}/login`
                  : "/"
              }
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  sessionId: state.app.sessionId,
  token: state.app.bearerToken,
});

const connector = connect(mapStateToProps);

export default connector(AuthRoute);
