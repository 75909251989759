/**
 * Renders the appropriate content depending if the predicate is true or false
 */
import { logOutLocally } from "../actions";
import { EValidationTestType } from "./validationConfigs";

// import { IDictionary } from '../models';

export function renderIf(
  condition: boolean,
  content: {
    ifTrue: () => JSX.Element | JSX.Element[];
    ifFalse: () => JSX.Element | JSX.Element[];
  }
): JSX.Element | JSX.Element[] {
  if (condition) {
    return content.ifTrue();
  } else {
    return content.ifFalse();
  }
}

/**
 * Renders the given content only if the condition is true
 */
export function renderIfTrue(
  condition: boolean,
  content: () => JSX.Element
): JSX.Element | JSX.Element[] | null {
  if (condition) {
    return content();
  }
  return null;
}

/**
 * Renders the given content only if the condition is false
 */
export function renderIfFalse(
  condition: boolean,
  content: () => JSX.Element | JSX.Element[]
): JSX.Element | JSX.Element[] | null {
  if (!condition) {
    return content();
  }
  return null;
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefinedTrue<T>(
  data: T,
  content: (data: T) => JSX.Element
): JSX.Element | null {
  if (typeof data !== "undefined" && data !== null) {
    return content(data);
  } else {
    return null;
  }
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefined<T>(
  data: T,
  content: {
    isDefined: (data: T) => JSX.Element | JSX.Element[];
    isNotDefined: () => JSX.Element | JSX.Element[];
  }
): JSX.Element | JSX.Element[] {
  if (typeof data !== "undefined" && data !== null) {
    return content.isDefined && content.isDefined(data);
  } else {
    return content.isNotDefined && content.isNotDefined();
  }
}

/**
 * Renders a callback from the callbacks dictionary based on a key (similar to the switch statement)
 * @param key               Key of the callback to call
 * @param callbacks         All the available callbacks
 * @param defaultCallback   Available if nothing else matches
 */
export function renderSwitch(
  key: string | number,
  callbacks: IDictionary<() => JSX.Element>,
  defaultCallback?: () => JSX.Element
): JSX.Element | null {
  if (callbacks[key]) {
    return callbacks[key]();
  } else {
    if (defaultCallback) {
      return defaultCallback();
    } else {
      return null;
    }
  }
}

export const parseDate = (str, type?) => {
  // console.log("parseDate utils: ", str);
  if (/\d{4}\-\d{2}\-\d{2}/gm.test(str)) {
    if (str != null && str != "") {
      var d = new Date(str),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hours = "" + d.getHours(),
        minutes = "" + d.getMinutes();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hours.length < 2) hours = "0" + hours;
      if (minutes.length < 2) minutes = "0" + minutes;

      if (type && type == "hr-min") {
        return (
          [day, month, year].join(".") + " - " + [hours, minutes].join(":")
        );
      }
      // console.log("will return date matched: ", day, month, year);
      const date = [day, month, year].join(".");
      if (date.includes("NaN")) {
        return;
      }
      return date;
    } else {
      return "";
    }
  } else {
    return str;
  }
};

export const parseToApiDate = (str, type?) => {
  if (/\d{2}\.\d{2}\.\d{4}/gm.test(str)) {
    const parts = str.split(".");
    return parts[2] + "-" + parts[1] + "-" + parts[0] + "T00:00:00";
  }
  return str;
};

export function removeEmptyObjects(o) {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      continue; // If null or not an object, skip to the next iteration
    }
    // The property is an object
    removeEmptyObjects(o[k]); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
  return o;
}

export function emptyStringsToNull(o) {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      if (o[k] === "") {
        console.log("matched empty string: ", k);
        o[k] = null;
      }
      if (typeof o[k] === "undefined") {
        delete o[k];
      }
    } else {
      emptyStringsToNull(o[k]);
    }
  }
  return o;
}

export function formatNumber(num) {
  // console.log("formatNumber: ", num);
  if (!num) {
    return num;
  }
  if (typeof num == "string" && /[a-zA-Z]/.test(num)) {
    return num;
  }
  if (typeof num == "string") {
    num = num.replace(/'/g, "").replace(/,/g, ".");
  }
  return parseFloat(num)
    .toFixed(2)
    .replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "'" + c : c;
    });
}

export function inputIsRequired(validationTests) {
  return (
    validationTests.indexOf(EValidationTestType.hasValue) >= 0 ||
    validationTests.indexOf(EValidationTestType.hasAnyValue) >= 0
  );
}

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function compareObjects(obj1, obj2) {
  console.log("VERSICHERTE", obj1, obj2);
  return JSON.stringify(obj1) == JSON.stringify(obj2);
}

export function percentage(max, value) {
  console.log("PROGRESS", max, value);
  return max && value ? (value * 100) / max : 0;
}

export function getLatestCsvImport(csv_imports) {
  let sorted = csv_imports.sort((a, b) => {
    return (
      new Date(b["attributes"]["imported-at"]).getTime() -
      new Date(a["attributes"]["imported-at"]).getTime()
    );
  });
  return sorted[0];
}

export function formatCsvImportDate(string) {
  // "2020-08-06T11:06:29.2206086"
  const date = string.split("T")[0];
  const time = string.split("T")[1];
  const splitDate = date.split("-");
  const splitTime = time.split(":");
  const formattedDate = [splitDate[2], splitDate[1], splitDate[0]].join(".");
  const formattedTime = [splitTime[0], splitTime[1]].join(":");
  return formattedDate + ", " + formattedTime;
}

export const hasParentWithMatchingSelector = (target, el) => {
  return el.contains(target);
  // return [...document.querySelectorAll(selector) as any].some(el =>
  //     el !== target && el.contains(target)
  // )
};

export const isTokenExpired = (token, expirationDate, authenticationStep?) => {
  let now = new Date().getTime();
  let expiration = new Date(expirationDate).getTime();
  if (
    !token ||
    (expirationDate &&
      expiration <= now &&
      authenticationStep != null &&
      authenticationStep != "2fa")
  ) {
    logOutLocally();
    return true;
  } else {
    return false;
  }
};

export const checkAuthStatus = (
  sessionId: string,
  token: string,
  path: string
) => {
  if (token && sessionId) {
    return true;
  } else {
    logOutLocally();
    return false;
  }
};

export const prepareDropdownOptions = (entities: string[]) => {
  if (!entities || !Object.keys(entities).length) {
    return [];
  }

  const keyToRemove = "keinCode";
  if (keyToRemove in entities) {
    delete entities[keyToRemove];
  }

  return Object.entries(entities).map(([key, value]) => ({
    value: key,
    label: value,
  }));
};

export type CountriesEntities = {
  id: string;
  name: string;
  description: string | null;
};

export const prepareCountriesEntitiesDropdownOptions = (
  entities: CountriesEntities[]
) => {
  if (!entities || !Object.keys(entities).length) {
    return [];
  }

  return entities.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};
