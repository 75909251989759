import { LOG_OUT, SET_VERSICHERUNGEN_DATA } from "../constants/actions";
import { Action } from "../actions";
import { VersicherungenData } from "../actions/versicherungen";

export interface VersicherungenState {
  versicherungenData: VersicherungenData;
}

const getInitialState = () => {
  return {
    versicherungenData: {
      title: "Versicherungsübersicht",
      text: "Hier finden Sie alle Angaben zu Ihrer Versicherung. Schnell und übersichtlich",
      graphBox: {
        title: "Übersicht aktuelle Versicherung",
        value: 0,
        subTitle: "Aktuelles Sparguthaben CHF",
        historicalGraph: [],
        futureGraph: [],
      },
      simulationBox: {
        title: "Simulation",
        toolTip: "",
        text: "Simulieren Sie verschiedene Vorsorgeszenarien und finden Sie heraus, wie sich diese auf Ihre finanzielle Situation auswirken",
        link: "",
        linkText: "SIMULIEREN STARTEN",
        isVisible: false,
      },
      personalInformation: {
        firstname: "",
        lastname: "",
        birthdate: "",
        socialSecurityNumber: "",
        civilStatusName: "",
        admissionDate: "",
        companyName: "",
        activityRate: 0,
      },
      insuranceCertificateBox: {
        title: "Vorsorgeausweis",
        toolTip: "",
        downloadLink: "",
        orderLink: "",
        isVisible: false,
      },
      knowledgeBox: {
        title: "Verstehen",
        text: "Auf der Wissensseite finden Sie Erläuterungen und Begriffserklärungen.",
        link: "",
        isVisible: false,
      },
      contactBox: {
        title: "Kontakt",
        text: "Gerne beraten wir Sie persönlich.",
        link: "",
        linkText: "Kontakt aufnehmen",
        isVisible: false,
      },
      creditbalanceDetailsBox: {
        title: "Aktuelles Sparguthaben",
        subTitle: "Zusammensetzung Sparguthaben",
        creditBalanceDetailsList: [],
        isVisible: false,
      },
      payrollAndContributionBox: {
        title: "Aktueller Lohn und Beiträge",
        payrollDetailsBox: {
          title: "Lohn",
          value: 0,
          payrollDetailsList: [],
          isVisible: false,
        },
        contributionDetailsBox: {
          title: "Beiträge",
          subTitle: "Spar und Risikobeiträg",
          perMonthLabel: "pro Monat",
          perYearLabel: "pro Jahr",
          contributionDetailsList: [],
          isVisible: false,
        },
        isVisible: false,
      },
      regularRetirementBox: {
        title: "Voraussichtliche Altersleistung ab 65. Altersjahr",
        subTitle: "mit 2% Zins pro Jahr",
        annuityDetailsObject: {
          capitalGroupLabel: "Kapital",
          capitalLabel: "voraussichtliches Kapital",
          capitalHelpText:
            "Das ist ein Hilfetext zum voraussichtlichen Kapital",
          conversionRateLabel: "Umwandlungssatz",
          conversionRateHelpText:
            "Das ist ein Hilfetext zum voraussichtlichen Kapital",
          capital: 0,
          conversionRate: "",
          annuityGroupLabel: "Altersrente",
          valuePerMonthLabel: "pro Monat",
          valuePerYearLabel: "pro Jahr",
          annuityLabel: "voraussichtliche Altersrente",
          annuityPerMonth: 0,
          annuityPerYear: 0,
        },
        isVisible: false,
      },
      invalidityAndDeathBenefitBox: {
        title: "Leisungen bei Invalidität oder im Todesfall",
        invalidityBenefitsList: [],
        fatalityBenefitsList: [],
        moreInformationLabel:
          "Weitereführende Informationen für Invalititätes- und Todesfallleistungen",
        benficiaryLinkText: "BEGÜNSTIGE IM TODESFALL",
        civilStatusLinkText: "ZIVILSTAND / KONKUBINAT",
        isVisible: false,
      },
    },
  };
};

const app = (
  state: VersicherungenState = getInitialState(),
  action: Action
) => {
  switch (action.type) {
    case SET_VERSICHERUNGEN_DATA: {
      return { ...state, versicherungenData: action.payload };
    }

    case LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;
