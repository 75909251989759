import { Action } from "./index";
import refreshToken from "./refreshToken";
import {
  SET_NEW_EMAIL_ADDRESS,
  SET_NEW_PHONE_NUMBER,
  SET_NEW_RESTORE_EMAIL_ADDRESS,
  SET_PROFILE,
  SET_CONFIRMATION_TOKEN,
  SET_PROFILE_ADDRESS,
} from "../constants/actions";
import { privateApi as API } from "../utils/api";
import { preparePatchPayload } from "../utils";

export const setNewEmailAddress = (value): Action => ({
  type: SET_NEW_EMAIL_ADDRESS,
  payload: value,
});

export const setNewRestoreEmailAddress = (value): Action => ({
  type: SET_NEW_RESTORE_EMAIL_ADDRESS,
  payload: value,
});

export const setNewPhoneNumber = (value): Action => ({
  type: SET_NEW_PHONE_NUMBER,
  payload: value,
});

export const getProfile = () => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response = await API.get("users/current/profile", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      dispatch({ type: SET_PROFILE, payload: response.data.data });
      await refreshToken(null);
      return response;
    } catch (err) {
      return err;
    }
  };
};

export const mobileFoneConfirmation = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response = await API.put(
        "users/current/profile/mobilefoneconfirmation",
        { data: payload }
      );
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const emailConfirmation = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response = await API.put(
        "users/current/profile/emailconfirmation",
        { data: payload }
      );
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const updateProfileLoginData = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response = await API.put("users/current/profile/logindata", {
        data: payload,
      });
      dispatch({ type: SET_CONFIRMATION_TOKEN, payload: response.data.data });
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const updateProfileAddress = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {

      const response = await API.patch("users/current/profile/address", preparePatchPayload(payload));
      return response;
    } catch (err) {
      throw err;
    }
  };
};

export const updateCivilStatus = (payload: any) => {
    const token = localStorage.getItem("ip_access_token");
    return async function (_dispatch) {
        try {
            const response = await API.patch("users/current/profile/civilstatus", preparePatchPayload(payload), {
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            return response;
        } catch (err) {
            throw err;
        }
    }
};
