import { logOutLocally, setI18NLoading } from "./";
import store from "../store";
import { privateApi, publicApi } from "../utils/api";

async function refreshToken(error) {
  store.dispatch(setI18NLoading(true));
  return new Promise((resolve, reject) => {
    try {
      const accessToken = localStorage.getItem("ip_access_token");
      const refreshToken = localStorage.getItem("ip_refresh_token");
      const header = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      };

      const body = {
        data: {
          accessToken,
          refreshToken,
        },
      };
      publicApi
        .post("sessions/refreshtoken", body, { headers: header })
        .then(async (res) => {
          localStorage.setItem("ip_access_token", res.data.data.bearerToken);
          localStorage.setItem("ip_refresh_token", res.data.data.refreshToken);

          privateApi.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.data.bearerToken;
          resolve(res);
          store.dispatch(setI18NLoading(false));
          //window.location.reload();
          return res;
        })
        .catch((err) => {
          // Fazer algo caso não seja feito o refresh token
          store.dispatch(logOutLocally());
          //window.location.reload();
          return reject(error);
        });
    } catch (err) {
      return reject(err);
    }
  });
}

export default refreshToken;
