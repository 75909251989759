import { SET_DESKTOP_DATA } from "../constants/actions";
import store from "../store";
import { privateApi as API } from "../utils/api";
import refreshToken from "./refreshToken";

export type DashboardData = {
  balanceBox: {
    hideAwaitBox: boolean;
    historicalGraph: { date: string; value: number }[];
    futureGraph: { date: string; value: number }[];
    subTitle: string;
    title: string;
    value: number;
    isActive: boolean;
  };
  civilStatusBox: {
    hideAwaitBox: boolean;
    civilStatus: string;
    subTitle: string;
    title: string;
    isVisible: boolean;
    isActive: boolean;
  };
  fatalityBox: {
    hideAwaitBox: boolean;
    amount: number;
    subTitle: string;
    title: string;
    isVisible: boolean;
    isActive: boolean;
  };
  faqBox: {
    hideAwaitBox: boolean;
    isVisible: boolean;
    title: string;
    toolTip: string;
  };
  homeOnwershipBox: {
    hideAwaitBox: boolean;
    maxAmount: number;
    projectedGraph: { year: number; date: string; value: number }[];
    subTitle: string;
    noDataInfo: string;
    projectedGraphUnavailable: boolean;
    title: string;
    isVisible: boolean;
    isActive: boolean;
  };
  inboxBox: {
    hideAwaitBox: boolean;
    amount: number;
    title: string;
    unreadDocumentLabel: string;
    isVisible: boolean;
    isActive: boolean;
  };
  additionalSavingsBox: {
    hideAwaitBox: boolean;
    balanceMaxRatio: number;
    currentCreditBalance: number;
    graphData: {
      graphMaxValue: number;
      title: string;
      historicalGraph: { year: number; date: string; value: number }[];
      subTitle: string;
      value: number;
    }[];
    graphMaxValue: number;
    isActive: boolean;
    isVisible: boolean;
    maxPurchaseAmount: number;
    maxBalanceAmount: number;
    planName: string;
    planSubtile: string;
    subTitle: string;
    title: string;
  };
  purchaseBox: {
    hideAwaitBox: boolean;
    balanceMaxRatio: number;
    maxBalanceAmount: number;
    maxPurchaseAmount: number;
    subTitle: string;
    title: string;
    currentCreditBalance: number;
    tooltipTitle: string;
    tooltipLabel: string;
    tooltipSubtitle: string;
    isVisible: boolean;
    isActive: boolean;
  };
  quittingBox: {
    hideAwaitBox: boolean;
    amount: number;
    subTitle: string;
    title: string;
    isVisible: boolean;
    isActive: boolean;
  };
  processingMessageBox: {
    processingDate: string;
    showProcessingDate: boolean;
  };
  retirementBox: {
    hideAwaitBox: boolean;
    defaultRetirementAge: number;
    amount: number;
    subTitle: string;
    title: string;
    isVisible: boolean;
    isActive: boolean;
    projectedGraph: {
      date: string;
      year: number;
      age: number;
      value: number;
    }[];
  };
  unpaidVacationBox: {
    hideAwaitBox: boolean;
    amount: number;
    subTitle: string;
    title: string;
    isVisible: boolean;
    isActive: boolean;
  };
  messageBox: {
    hideAwaitBox: boolean;
    title: string;
    text: string;
    isVisible: boolean;
  };
};

export const getDashboardData = () => {
  return async function (dispatch) {
    try {
      const response: { data: { data: DashboardData; meta } } = await API.get(
        "dashboard"
      );
      const data = {
        ...response.data.data,
        messageBox: response.data.meta.messageBox,
      };
      dispatch({ type: SET_DESKTOP_DATA, payload: data });
      if (!store.getState().app.i18nLoading) await refreshToken(null);
      return response;
    } catch (err) {
      return err;
    }
  };
};
