import { useSelector } from "react-redux";
import { Provider } from "urql";
import { Client, cacheExchange, fetchExchange } from "urql";
import { Loader } from "@icr-ui/core";

import { RootState } from "../reducers";

export const UrqlProvider = ({ children }) => {
  const bearerToken = useSelector(
    (state: RootState) => state?.app?.bearerToken
  );
  const { url: graphqlApiUrl } = useSelector(
    (state: RootState) => state?.app?.hygraph
  );

  if (!bearerToken) {
    return children;
  }

  if (graphqlApiUrl.length === 0) {
    return <Loader />;
  }

  const urqlClient = new Client({
    url: graphqlApiUrl,
    exchanges: [cacheExchange, fetchExchange],
  });

  return <Provider value={urqlClient}>{children}</Provider>;
};

export default UrqlProvider;
