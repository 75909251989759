import { SET_ZIVILSTAND_DATA } from "../constants/actions";
import { privateApi as API } from "../utils/api";
import refreshToken from "./refreshToken";

export type ZivilstandData = {
  title: string;
  text: string;
  civilStatusBox: {
    civilStatus: string;
    subTitle: string;
    isVisible: boolean;
  };
  actionsBox: {
    title: string;
    text: string;
    isVisible: boolean;
    actionList: {
      title: string;
      text: string;
      link: string;
      linkText: string;
    }[];
  };
  simulationBox: {
    title: string;
    toolTip: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  civilStatusReportBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  fatalityLinkBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  contactBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  civilStatusContentBox: {
    content: string;
  };
};

export const getZivilstandData = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response: { data: { data: ZivilstandData } } = await API.get(
        "pages/civilstatus"
      );
      dispatch({
        type: SET_ZIVILSTAND_DATA,
        payload: response.data.data,
      });
      await refreshToken(null);
      return response;
    } catch (err) {
      return err;
    }
  };
};
