import { useEffect, useState } from "react";
import {
  Button,
  ButtonColors,
  ButtonSizes,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { ButtonStyles } from "icr-shared-components/dist";
import classNames from "classnames";
import { bindActionCreators, Dispatch } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Card from "../../components/Card";
import "../Versicherungen/Versicherungen.scss";
import "./Kontakt.scss";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { getContact, submitContactRequest } from "../../actions/contact";
import { RootState } from "../../reducers";
import { Action, setLoading } from "../../actions";
import { useTranslation } from "react-i18next";
import { toaster } from "../../utils/toaster";
import { cmsSchemas } from "../../constants";
import { useGetSidebarCMSContent } from "../../hooks/cms";
import { HygraphSidebarComponents } from "../../components/sidebar/HygraphSidebarComponents";

interface State {
  subject: string;
  body: string;
}

interface Props {
  history: any;
  location: any;
  getContact: any;
  contact: any;
  submitContactRequest: any;
  setLoading: any;
}

const Kontakt = (props: Props) => {
  const schema = cmsSchemas.contact.plural;
  const { content: cmsSidebarContent } = useGetSidebarCMSContent({ schema });
  const sidebarBoxes = cmsSidebarContent?.sidebar;

  const { t: translate } = useTranslation(["contactPage", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "contactPage", defaultValue });

  const [state, setState] = useState<State>({
    subject: "",
    body: "",
  });

  useEffect(() => {
    props.getContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    try {
      props.setLoading(true);
      await props.submitContactRequest({
        subject: state.subject,
        body: state.body,
      });
      toaster({
        message: "Nachricht erfolgreich gesendet",
        type: "success",
      });
      setState({ subject: "", body: "" });
    } catch (err) {
      console.error("error submitting contact form", err);
    } finally {
      props.setLoading(false);
    }
  };

  const contactData = props.contact
    ? Object.keys(props.contact).reduce((res, k) => {
        if (props.contact[k] !== null) res.push(props.contact[k]);
        return res;
      }, [])
    : [];

  const contactDetailsReactManagedTranslation = sidebarBoxes?.find(
    (item) => item?.box?.boxKey === "contactDetailsBox"
  )?.box?.component;

  const contactDetailsReactManagedTranslationTitle =
    contactDetailsReactManagedTranslation?.find(
      (item) => item.__typename === "UITitle"
    )?.text;

  const reactManagedSidebarComponents = {
    contactDetailsBox: (
      <div className="p-48 d-flex flex-column">
        {contactDetailsReactManagedTranslationTitle && (
          <h5 className="mb-3">{contactDetailsReactManagedTranslationTitle}</h5>
        )}
        <ul className="kontakt-daten">
          {contactData.map((d, i) => {
            return (
              <li
                key={`contact-data-${i}`}
                className={classNames({
                  "mt-2":
                    i === contactData.length - 1 ||
                    i === contactData.length - 2,
                })}
              >
                {d}
              </li>
            );
          })}
        </ul>
      </div>
    ),
  };

  return (
    <div className="versicherungen kontakt">
      <div className="page-top">
        <Button
          color={ButtonColors.Action}
          size={ButtonSizes.Full}
          type={ButtonTypes.Text}
          onClick={() => props.history.goBack()}
        >
          <Arrow className="rotate-180" />
          <span className="ml-16">
            {" "}
            {translate("buttons.goBack", { ns: "app", defaultValue: "Zurück" })}
          </span>
        </Button>
      </div>

      <h1 className="mb-48 pl-0 font-weight-bold">
        {t("page.title", "Kontakt")}
      </h1>

      <div className="two-column-layout mb-48">
        <div className="left">
          <Card>
            <div className="p-48">
              <h3 className="title-card mb-48">
                {t("page.subtitle", "Nachricht senden")}
              </h3>

              <Input
                type="text"
                name="subject"
                value={state.subject}
                onChange={(name, value) =>
                  setState({ ...state, subject: value })
                }
                placeholder={t("form.subject.placeholder", "Betreff eingeben")}
                inputWrapperClass="mb-48"
              >
                {t("form.subject.label", "Betreff")}
              </Input>

              <div className="contact-text-area">
                <Input
                  type="textarea"
                  rows={8}
                  name="body"
                  value={state.body}
                  onChange={(name, value) =>
                    setState({ ...state, body: value })
                  }
                  placeholder={t(
                    "form.request.placeholder",
                    "Ihr Anliegen eingeben"
                  )}
                  inputWrapperClass="textarea-input contact-text-area mb-48"
                >
                  {t("form.request.label", "Ihr Anliegen")}
                </Input>
              </div>

              <div className="d-flex justify-content-end">
                <Button
                  size={ButtonSizes.Auto}
                  color={ButtonColors.Action}
                  type={ButtonTypes.Standard}
                  style={ButtonStyles.Primary}
                  onClick={handleSubmit}
                >
                  {translate("buttons.send", {
                    ns: "app",
                    defaultValue: "Senden",
                  })}
                </Button>
              </div>
            </div>
          </Card>
        </div>

        <div className="right">
          <div className="mb-48">
            <Card>
              {sidebarBoxes && (
                <HygraphSidebarComponents
                  boxes={sidebarBoxes}
                  reactManagedSidebarComponents={reactManagedSidebarComponents}
                  avoidBoxValidation
                />
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  contact: state.app.contact,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getContact: bindActionCreators(getContact, dispatch),
  setLoading: (value) => dispatch(setLoading(value)),
  submitContactRequest: bindActionCreators(submitContactRequest, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Kontakt));
