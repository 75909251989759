import { Suspense } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { Route, BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./styles/index.scss";
import "./locale/i18n";

import { AppProvider } from "./providers";
import Loader from "./components/Loader";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

ReactDOM.render(
  <Worker workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`}>
    <Suspense fallback={<Loader />}>
      <Router>
        <AppProvider>
          <Route path="/" component={App} />
        </AppProvider>
      </Router>
    </Suspense>
  </Worker>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
