import classNames from "classnames";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Dispatch, bindActionCreators } from "redux";
import { Action, globalSettings, setLoading } from "../../actions";
import { loginSms, setLoginPhoneNumber, setSession } from "../../actions/login";
import Card, { Width } from "../../components/Card";
import Header from "../../components/Login/Header/headerLogin";
import { RootState } from "../../reducers";
import {
  getLanguageFromUrl,
  getQueryParameter,
  getTenantFromUrl,
} from "../../utils";
import Email from "./Email";
import Link from "./Link";
import "./Login.scss";
import Reminder from "./Reminder";
import Sms from "./Sms";
import { useTranslation } from "react-i18next";

interface LoginProps {
  history: any;
  loginSms: any;
  setSession: any;
  setLoading: any;
  setLoginPhoneNumber: any;
  logoUrl: any;
  configurations: any;
}

const Login = (props: LoginProps) => {
  const { i18n } = useTranslation();

  const onLoad = async (): Promise<void> => {
    const { loginSms, setSession, history, setLoading, setLoginPhoneNumber } =
      props;
    const session_id = getQueryParameter("sessionid");
    const email_code = getQueryParameter("emailcode");
    const user_language = getLanguageFromUrl();

    if (user_language) {
      i18n.changeLanguage(user_language);
    }
    if (session_id && email_code) {
      setLoading(true);
      await loginSms({ emailCode: email_code }, session_id)
        .then((res) => {
          setLoading(false);
          setSession(session_id);
          setLoginPhoneNumber(res.data.data.mobileFone);
          history.push(`${getTenantFromUrl()}/${i18n.language}/login/sms`);
        })
        .catch((err) => {
          history.push(`${getTenantFromUrl()}/${i18n.language}/login/email`);
        });
    }
  };

  useEffect(() => {
    onLoad();
    return () => {
      document.body.classList.remove("image-background");
    };
  }, []);

  const { configurations } = props;
  const backgroundImage = configurations[i18n?.language]?.backgroundImage;
  const logoImage = configurations[i18n?.language]?.logoImage;

  return (
    <div
      className={classNames("login-page", {
        "default-background": backgroundImage == null,
      })}
      style={
        backgroundImage
          ? { backgroundImage: "url('" + backgroundImage + "')" }
          : null
      }
    >
      <Card width={Width.Login}>
        <Header logoUrl={logoImage} />

        <div className="login-content">
          <Switch>
            <Route
              path={`${getTenantFromUrl()}/${i18n.language}/login/link`}
              component={Link}
            />
            <Route
              path={`${getTenantFromUrl()}/${i18n.language}/login/sms`}
              component={Sms}
            />
            <Route
              path={`${getTenantFromUrl()}/${i18n.language}/login/reminder`}
              component={Reminder}
            />
            <Route
              path={`${getTenantFromUrl()}/${i18n.language}/login`}
              component={Email}
            />
          </Switch>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  logoUrl: state.app.logoUrl,
  configurations: state.app.configurations,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoading: (value) => dispatch(setLoading(value)),
  setSession: (value) => dispatch(setSession(value)),
  globalSettings: bindActionCreators(globalSettings, dispatch),
  loginSms: bindActionCreators(loginSms, dispatch),
  setLoginPhoneNumber: bindActionCreators(setLoginPhoneNumber, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Login);
