import Modal from "react-modal";
import PdfViewer from "../../components/PdfViewer";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right.svg";
import { Button, ButtonColors, ButtonTypes } from "icr-shared-components/dist";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { SET_OPEN_DOCUMENT } from "../../constants";

interface Props {
  closeModal: any;
  onDocumentLoad: any;
  pdfLink: any;
  isModalOpen: boolean;
}

const Document = (props: Props) => {
  const { t: translate } = useTranslation(["app"]);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch({
      type: SET_OPEN_DOCUMENT,
      payload: false,
    });
    props.closeModal();
  };
  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isModalOpen}
      className="modal dokumente-modal"
      overlayClassName="modal-overlay dokumente-modal-overlay"
      contentLabel="Profile modal"
      bodyOpenClassName="ReactModal__Body--open pdf"
    >
      <div className="dokumente-modal-wrapper">
        <Button
          className="mt-58"
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          onClick={closeModal}
        >
          <Arrow className="rotate-180" />{" "}
          <span className="ml-16">
            {translate("buttons.goBack", { ns: "app", defaultValue: "Zurück" })}
          </span>
        </Button>

        <PdfViewer link={props.pdfLink} onDocumentLoad={props.onDocumentLoad} />
      </div>
    </Modal>
  );
};

const connector = connect(null, null);

export default connector(Document);
