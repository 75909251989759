import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import styles from "./style.module.scss";

interface Props {
  logoUrl?: string;
}

export default function Header(props: Props) {
  return (
    <div className="d-flex justify-content-between">
      {props.logoUrl == null && <Logo />}
      {props.logoUrl && (
        <img className={styles.headerLogo} src={props.logoUrl} alt="" />
      )}
      <LanguageSelector />
    </div>
  );
}
