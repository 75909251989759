import axios from "axios";
import jwt from "jwt-decode";
import { store } from "../store";
import { logOutLocally, setLoading } from "../actions";
import { IBearerToken } from "../reducers/app";
import refreshToken from "../actions/refreshToken";
import { toaster } from "./toaster";
import { getTenantFromUrl } from ".";
import { RouteURLsByLanguage, DefaultRouteNames } from "../routes";

export const baseURL = process.env.REACT_APP_REST_API_URL;

export const publicApi = axios.create({
  baseURL:
    baseURL ||
    "https://development.api.insurantportal.pensiondynamics.ch/jsonapi/v1/",
});

export const privateApi = axios.create({
  baseURL:
    baseURL ||
    "https://development.api.insurantportal.pensiondynamics.ch/jsonapi/v1/",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("ip_access_token"),
  },
});

privateApi.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("ip_access_token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

publicApi.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    if ([401, 400, 500].includes(error.response.status)) {
      let errors = error?.response?.data?.errors;

      // remove expired token error
      errors = errors.filter((err) => err.code !== "err_3_3_2_4_109");

      if (errors && errors.length) {
        errors.forEach((error) => {
          toaster({
            message: error.detail,
            type: "error",
          });
        });
      }

      store.dispatch(setLoading(false));
    }

    if (error.response.status === 403) {
      store.dispatch(logOutLocally());
      store.dispatch(setLoading(false));
      window.open(
        `${window.location.origin}${
          window.location.href.toLowerCase().includes("cpval")
            ? "/CPVAL"
            : getTenantFromUrl()
        }/de-CH${
          RouteURLsByLanguage[`${DefaultRouteNames.FORBIDDEN}-${"de-CH"}`]
        }`,
        "_self"
      );
    }

    return Promise.reject(error);
  }
);

privateApi.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    console.log({ ...error });
    const bearerToken = localStorage.getItem("ip_access_token");

    if ([401, 500].includes(error.response.status) && bearerToken) {
      const user: IBearerToken = jwt(bearerToken);

      // if user.exp is less than current time, then token is expired, so refresh it
      if (user.exp < Date.now() / 1000) {
        const refreshResponse = await refreshToken(error);
        return refreshResponse;
      }
      const notRegisteredErrorCode = "err_3_3_2_42";
      const registrationInProgressErrorCode = "err_3_3_2_210";
      const tokenExpiredErrorCode = "err_3_3_2_4_109";
      const errorCode = error?.response?.data?.errors?.[0]?.code;

      const showPopup =
        (notRegisteredErrorCode != errorCode &&
          registrationInProgressErrorCode != errorCode) ||
        tokenExpiredErrorCode != errorCode;

      if (showPopup) {
        const errors = error?.response?.data?.errors;
        if (errors && errors.length) {
          errors.forEach((error) => {
            toaster({
              message: error.detail,
              type: "error",
            });
          });
        }
        store.dispatch(logOutLocally());
        store.dispatch(setLoading(false));
      }
    }

    if (error.response.status === 403) {
      store.dispatch(logOutLocally());
      store.dispatch(setLoading(false));
      window.open(
        `${window.location.origin}${getTenantFromUrl()}/de-CH${
          RouteURLsByLanguage[`${DefaultRouteNames.FORBIDDEN}-${"de-CH"}`]
        }`,
        "_self"
      );
    }

    return Promise.reject(error);
  }
);
