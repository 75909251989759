import {
  SET_DOCUMENT_DATA,
  SET_DOCUMENT_LIST,
  SET_DOCUMENTS_SUMMARY,
} from "../constants/actions";
import { privateApi as API } from "../utils/api";
import axios from "axios";

export type DocumentSummary = {
  unreadDocumentsAmount: number;
  unreadDocumentsLabel: string;
  dateLabel: string;
  documentTypeLabel: string;
  documentStateLabel: string;
  orderDocumentButtonText: string;
  isDocumentOrderingEanabled: boolean;
};
export type DocumentList = {
  id: string;
  date: string;
  documenttype: string;
  stateLabel: string;
  unread: boolean;
  pdfLink: string;
}[];

export type DocumentData = {
  isReady: boolean;
  fileCode: string;
  hasError: boolean;
};

export const getPdf = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response: any = await axios.get(payload, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      return response;
    } catch (err) {
      return err;
    }
  };
};

export const insuranceFormSet = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response: { data: { data: DocumentList } } = await API.get(
        "documents/insuranceFormSet",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  };
};

export const generateInsuranceFormSet = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      let url = "documents/generateInsuranceFormSet";
      if (payload.fileCode !== null) {
        url += `?fileCode=${payload.fileCode}`;
      }

      const response = await API.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      dispatch({
        type: SET_DOCUMENT_DATA,
        payload: response.data.data,
      });
      return response.data.data;
    } catch (err) {
      return err;
    }
  };
};

export const getDocumentsSummary = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response: { data: { data: DocumentList } } = await API.get(
        "documents/summary",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch({ type: SET_DOCUMENTS_SUMMARY, payload: response.data.data });
      return response;
    } catch (err) {
      return err;
    }
  };
};

export const getDocumentList = (payload) => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response: { data: { data: DocumentSummary } } = await API.get(
        "documents",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch({ type: SET_DOCUMENT_LIST, payload: response.data.data });
      return response;
    } catch (err) {
      return err;
    }
  };
};
