import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { ButtonStyles } from "icr-shared-components/dist";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Dispatch, bindActionCreators } from "redux";
import { Action, setLoading } from "../../../actions";
import { loginEmail, setEmail } from "../../../actions/login";
import { registrationCode } from "../../../actions/register";
import { RootState } from "../../../reducers";
import { useValidateFrom } from "../../../utils/useValidateFrom";
import "./Email.scss";
import { formConfig } from "./formConfig";

import { Languages } from "../../../constants/enums";
import { getTenantFromUrl } from "../../../utils";
import { getRouteByLanguage, DefaultRouteNames } from "../../../routes";

const defaultTenant = process.env.REACT_APP_DEFAULT_TENANT || "PKSO";

interface Props {
  email: any;
  setEmail: any;
  loginEmail: any;
  setLoading: any;
  registrationCode: any;
}

const Email: FunctionComponent<Props> = ({
  email,
  setEmail,
  loginEmail,
  registrationCode,
  setLoading,
}) => {
  const [isBlurred, setIsBlurred] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const history = useHistory();
  const { i18n, t: translate } = useTranslation(["auth", "validation"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { defaultValue });

  const next = async () => {
    const language = Languages[i18n.language.split("-")[0]];
    let tenant =
      getTenantFromUrl().replace("/", "") ||
      window.localStorage.getItem("tenantName");
    setLoading(true);
    try {
      await loginEmail({
        emailAddress: email,
        urlCallback:
          window.location.origin +
          `${getTenantFromUrl()}/${
            i18n.language
          }/login?sessionid=[token]&emailcode=[emailcode]`,
        tenant: tenant || defaultTenant,
        language: language,
      });
      setLoading(false);
      setDisableButton(false);
    } catch (err) {
      await registrationCode({
        emailAddress: email,
        tenant: tenant || defaultTenant,
        urlCallback:
          window.location.origin +
          `${getRouteByLanguage(
            DefaultRouteNames.REGISTER
          )}?sessionid=[token]&emailcode=[emailcode]`,
        language: language,
      });
      setLoading(false);
      setDisableButton(false);
    }
    setDisableButton(false);
    history.push(`${getTenantFromUrl()}/${i18n.language}/login/link`);
  };

  const validation = useValidateFrom("login", formConfig, { email });

  return (
    <React.Fragment>
      <form onSubmit={(e) => e.preventDefault()}>
        <h1 className="mb-40">
          {t("login.email.title", "Willkommen im Versichertenportal")}
        </h1>

        <p className="mb-24">
          {t(
            "login.email.findPensionInformation",
            "Hier finden Sie alle Informationen rund um Ihre Pensionskasse."
          )}
        </p>
        <p className="mb-32">
          {t(
            "login.email.pleaseLogin",
            "Bitte melden Sie sich mit Ihrer E-Mail-Adresse an."
          )}
        </p>

        <Input
          id="loginEmailInput"
          isFocused
          type="text"
          name="email"
          value={email}
          onChange={(name, value) => setEmail(value)}
          onBlur={() => setIsBlurred(true)}
          placeholder={t("login.email.input.placeholder", "E-Mail-Adresse")}
          inputWrapperClass="mb-48"
          validationMessageConfig={
            (isBlurred ||
              validation?.email?.validation?.type === "success") && {
              config: validation.email.validation,
              visible: true,
            }
          }
        >
          {t("login.email.input.label", "E-Mail-Adresse")}
        </Input>
        <div className="login-button-wrapper">
          <Button
            id="loginNextButton"
            isDisabled={
              validation?.email?.validation?.type !== "success" || disableButton
            }
            className="mx-auto bottom-button"
            style={ButtonStyles.Primary}
            type={ButtonTypes.Standard}
            color={ButtonColors.Action}
            submit
            onClick={() => {
              setDisableButton(true);
              next();
            }}
          >
            {t("login.email.submitButton.label", "Login Link empfangen")}
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  email: state.app.email,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setEmail: (value) => dispatch(setEmail(value)),
  setLoading: (value) => dispatch(setLoading(value)),
  loginEmail: bindActionCreators(loginEmail, dispatch),
  registrationCode: bindActionCreators(registrationCode, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Email);
