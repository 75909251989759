import React, { FunctionComponent, useState } from "react";
import { RootState } from "../../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action, setLoading } from "../../../actions";
import { connect } from "react-redux";
import {
  Button,
  ButtonColors,
  ButtonTypes,
  Input,
} from "icr-shared-components";
import { useHistory } from "react-router";
import {
  resendLetter,
  setRegistrationCode,
  validateLetter,
} from "../../../actions/register";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import Modal from "react-modal";
import Vegistrierungscode from "../../../assets/images/beispiel-vegistrierungscode.png";
import { ButtonStyles } from "icr-shared-components/dist";
import { formConfig } from "./formConfig";
import { useValidateFrom } from "../../../utils/useValidateFrom";
import { login } from "../../../actions/login";
import { useTranslation } from "react-i18next";
import { getRouteByLanguage, DefaultRouteNames } from "../../../routes";

interface Props {
  validateLetter: any;
  registrationCode: string;
  sessionId: string;
  setRegistrationCode: (value: string) => void;
  setLoading: any;
  resendLetter: any;
  login: any;
}

const Code: FunctionComponent<Props> = ({
  registrationCode,
  sessionId,
  setRegistrationCode,
  validateLetter,
  setLoading,
  login,
}) => {
  const { t: translate } = useTranslation(["register", "app"]);
  const t = (key: string, defaultValue: string) =>
    translate(key, { ns: "register", defaultValue });
  const [modal, setModal] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const history = useHistory();
  const validation = useValidateFrom("register", formConfig, {
    registrationCode,
  });

  const next = async () => {
    setLoading(true);
    try {
      const data = {
        letterCode: registrationCode,
      };
      const response = await validateLetter(data, sessionId);
      login(response);
      setDisableButton(false);
      setLoading(false);
      history.push(getRouteByLanguage(DefaultRouteNames.DASHBOARD));
    } catch (error) {
      setDisableButton(false);
      setLoading(false);
      console.log(error);
      console.error("error in confirming letter code");
    }
  };

  const resend = async () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERSEND));
  };

  const back = () => {
    history.push(getRouteByLanguage(DefaultRouteNames.REGISTERSMS));
  };

  return (
    <div className="register-birthday-page">
      <p className="mb-32">
        {t(
          "registrationCode.enterCodeSentbyPost",
          "Bitte geben Sie hier Ihren persönlichen Registrierungscode, den wir Ihnen per Post zugestellt haben ein."
        )}
      </p>

      <div className="row">
        <div className="col-sm-12 col-md-6">
          <Input
            type="text"
            id="registerRegistrationCodeInput"
            name="registerRegistrationCodeInput"
            value={registrationCode}
            onChange={(name, value) => setRegistrationCode(value)}
            placeholder={t(
              "registrationCode.placeholder",
              "Registrierungscode"
            )}
            inputWrapperClass="mb-16"
            onBlur={() => setIsBlurred(true)}
            validationMessageConfig={
              (isBlurred ||
                validation?.registrationCode?.validation?.type ===
                  "success") && {
                config: validation.registrationCode.validation,
                visible: true,
              }
            }
          >
            {t("registrationCode.label", "Registrierungscode")}
          </Input>
        </div>
      </div>
      <div className="left-align-button-wrapper">
        <Button
          onClick={() => setModal(true)}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
          className="mb-32"
        >
          {t(
            "registrationCode.whereToFind",
            "Wo finde ich den Registrierungscode?"
          )}
        </Button>
      </div>

      <h5 className="mb-16">
        {t(
          "registrationCode.didNotReceiveCode",
          "Keinen Registrierungscode erhalten?"
        )}
      </h5>

      <Button
        id="registerResendButton"
        onClick={() => resend()}
        type={ButtonTypes.Text}
        color={ButtonColors.Action}
        className="mb-48"
      >
        {t(
          "registrationCode.resendCode",
          "Registrierungscode Code erneut senden"
        )}
      </Button>

      <div className="modal-buttons">
        <Button
          id="registerBackButton"
          onClick={() => back()}
          type={ButtonTypes.Text}
          color={ButtonColors.Action}
        >
          {translate("buttons.goBack", { ns: "app", defaultValue: "Zurück" })}
        </Button>

        <Button
          id="registerNextButton"
          isDisabled={
            validation?.registrationCode?.validation?.type !== "success" ||
            disableButton
          }
          type={ButtonTypes.Standard}
          style={ButtonStyles.Primary}
          color={ButtonColors.Action}
          onClick={() => {
            setDisableButton(true);
            next();
          }}
        >
          {translate("buttons.next", { ns: "app", defaultValue: "Weiter" })}
        </Button>
      </div>

      <Modal
        isOpen={modal}
        className="modal register-modal"
        overlayClassName="modal-overlay register-modal-overlay"
        contentLabel="Register Popup"
      >
        <div className="modal-close-button" onClick={() => setModal(false)}>
          <CloseIcon />
        </div>

        <div className="register-modal-content">
          <h2 className="mb-24">
            {t(
              "registrationCode.modal.whereToFind",
              "Wo finde ich den Registrierungscode?"
            )}
          </h2>
          <p className="mb-24">
            {t(
              "registrationCode.modal.codeSentByPost",
              "Den Registrierungscode finden Sie im Schreiben, welches wir Ihnen per Post zugestellt haben."
            )}
          </p>
          <div className="register-modal-image-container mt-48">
            <img
              className="register-modal-image"
              src={Vegistrierungscode}
              alt="Vegistrierungscode"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  registrationCode: state.app.registrationCode,
  sessionId: state.app.sessionId,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  setLoading: (value) => dispatch(setLoading(value)),
  setRegistrationCode: (value) => dispatch(setRegistrationCode(value)),
  validateLetter: bindActionCreators(validateLetter, dispatch),
  resendLetter: bindActionCreators(resendLetter, dispatch),
  login: bindActionCreators(login, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Code);
