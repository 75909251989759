import { Action } from "./index";
import { publicApi as API } from "../utils/api";
import {
  LOGIN,
  SET_EMAIL,
  SET_LOGIN_PHONE_NUMBER,
  SET_LOGIN_SMS_CODE,
  SET_PHONE_NUMBER,
  SET_SESSION,
} from "../constants/actions";

export const setEmail = (value): Action => ({
  type: SET_EMAIL,
  payload: value,
});

export const setSmsCode = (value): Action => ({
  type: SET_LOGIN_SMS_CODE,
  payload: value,
});

export const setSession = (value): Action => ({
  type: SET_SESSION,
  payload: value,
});

export const setLoginPhoneNumber = (value): Action => ({
  type: SET_LOGIN_PHONE_NUMBER,
  payload: value,
});

export const login = (value): Action => ({
  type: LOGIN,
  payload: value,
});

export const loginEmail = (payload) => {
  return async function (dispatch) {
    try {
      const response = await API.post("sessions/email", { data: payload });
      return response;
    } catch (e) {
      throw e;
    }
  };
};

export const loginSms = (payload, id) => {
  return async function (dispatch) {
    try {
      const response = await API.patch(`sessions/sms/${id}`, { data: payload });
      return response;
    } catch (e) {
      throw e;
    }
  };
};

export const patchLogin = (payload, id) => {
  return async function (dispatch) {
    try {
      const response = await API.post(`sessions/login/${id}`, {
        data: payload,
      });
      return response;
    } catch (e) {
      throw e;
    }
  };
};

export const resendSms = (payload, id) => {
  return async function (dispatch) {
    try {
      const response = await API.patch(`sessions/${id}`, { data: payload });
      return response;
    } catch (e) {
      throw e;
    }
  };
};
