import * as constants from "../constants/actions";
import { Action } from "../actions";
import { DashboardData } from "../actions/dashboard";

export interface DashboardState {
  dashboardDataLoaded: boolean;
  dashboardData: DashboardData;
}

const getInitialState = () => {
  return {
    dashboardDataLoaded: false,
    dashboardData: {
      balanceBox: {
        hideAwaitBox: false,
        historicalGraph: [],
        futureGraph: [],
        subTitle: "Aktuelles Sparguthaben CHF",
        title: "Übersicht aktuelle Versicherung",
        value: 0,
        isActive: false,
      },
      civilStatusBox: {
        hideAwaitBox: false,
        civilStatus: "",
        subTitle: "aktueller Zivilstand",
        title: "Zivilstand / Konbubinat",
        isVisible: false,
        isActive: false,
      },
      fatalityBox: {
        hideAwaitBox: false,
        amount: 0,
        subTitle: "Todesfallkapital in CHF",
        title: "Im Todesfall",
        isVisible: false,
        isActive: false,
      },
      faqBox: {
        hideAwaitBox: false,
        isVisible: false,
        title: "",
        toolTip: "Häufige Fragen",
      },
      homeOnwershipBox: {
        hideAwaitBox: false,
        maxAmount: 0,
        projectedGraph: [],
        subTitle: "Aktuell maximal möglicher Einkauf in CHF",
        title: "Wohneigentum finanzieren",
        noDataInfo: "",
        projectedGraphUnavailable: false,
        isVisible: false,
        isActive: false,
      },
      inboxBox: {
        hideAwaitBox: false,
        amount: 0,
        title: "Dokumente",
        unreadDocumentLabel: "ungelesene Dokumente",
        isVisible: false,
        isActive: false,
      },
      processingMessageBox: {
        processingDate: "2024-04-12T14:02:20.4425+00:00",
        showProcessingDate: false,
      },
      purchaseBox: {
        hideAwaitBox: false,
        balanceMaxRatio: 0,
        maxBalanceAmount: 0,
        maxPurchaseAmount: 0,
        currentCreditBalance: 0,
        tooltipTitle: "",
        tooltipLabel: "",
        tooltipSubtitle: "",
        subTitle: "Aktuell maximal möglicher Einkauf in CHF",
        title: "Altersleistungen erhöhen und Steuern sparen",
        isVisible: false,
        isActive: false,
      },
      additionalSavingsBox: {
        hideAwaitBox: false,
        balanceMaxRatio: 0,
        currentCreditBalance: 0,
        graphData: [],
        graphMaxValue: 0,
        isVisible: false,
        isActive: false,
        maxBalanceAmount: 0,
        maxPurchaseAmount: 0,
        planName: "Basis",
        planSubtile: "Zusatzsparen Option aktuell",
        subTitle: "aktuell maximal möglicher Einkauf",
        title: "Altersleistungen erhöhen und Steuern sparen",
      },
      quittingBox: {
        hideAwaitBox: false,
        amount: 0,
        subTitle: "aktuelle Austrittsleistung in CHF",
        title: "Neuer Arbeitgeber/ Selbständig/ Auswandern",
        isVisible: false,
        isActive: false,
      },
      retirementBox: {
        hideAwaitBox: false,
        defaultRetirementAge: 65,
        amount: 0,
        subTitle: "voraussichtliche Monatsrente in CHF",
        title: "Pensionierung",
        projectedGraph: [],
        isVisible: false,
        isActive: false,
      },
      unpaidVacationBox: {
        hideAwaitBox: false,
        amount: 0,
        subTitle: "mögliche Finanzierung",
        title: "Unbezahlter Urlaub",
        isVisible: false,
        isActive: false,
      },
      messageBox: {
        hideAwaitBox: false,
        isVisible: false,
        title: "",
        text: "",
      },
    },
  };
};

const app = (state: DashboardState = getInitialState(), action: Action) => {
  switch (action.type) {
    case constants.SET_DESKTOP_DATA: {
      return {
        ...state,
        dashboardData: action.payload,
        dashboardDataLoaded: true,
      };
    }

    case constants.LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;
