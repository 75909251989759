import { SET_ALTERSLEISTUNG_DATA } from "../constants/actions";
import { FinanceBoxProps } from "../types";
import { privateApi as API } from "../utils/api";
import refreshToken from "./refreshToken";

export type AltersleistungData = {
  title: string;
  text: string;
  buyInOverviewBox: {
    purchaseBox: {
      maxPurchaseAmount: number;
      subTitle: string;
      homeOwnershipBalance: number;
      maxBalanceAmount: number;
      balanceMaxRatio: number;
      isVisible: boolean;
      currentCreditBalance: number;
      tooltipLabel: string;
      tooltipSubtitle: string;
      tooltipTitle: string;
    };
    link: string;
    linkText: string;
  };
  buyInActionsBox: {
    isVisible: boolean;
    title: string;
    text: string;
    actionList: {
      title: string;
      text: string;
      link: string;
      linkText: string;
    }[];
  };
  contactBox: {
    title: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  buyInActionsShortBox: {
    title: string;
    text: string;
    isVisible: boolean;
    actionsList: {
      link: string;
      linkText: string;
    }[];
  };
  simulationBox: {
    title: string;
    toolTip: string;
    text: string;
    link: string;
    linkText: string;
    isVisible: boolean;
  };
  financeExpertBox: FinanceBoxProps;
  buyInPageContentBox: {
    content: string;
  };
};

export const getAltersleistungData = () => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response: { data: { data: AltersleistungData } } = await API.get(
        "pages/buyin"
      );
      dispatch({
        type: SET_ALTERSLEISTUNG_DATA,
        payload: response.data.data,
      });
      await refreshToken(null);
      return response;
    } catch (err) {
      return err;
    }
  };
};
