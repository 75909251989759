import { privateApi as API } from "../utils/api";
import { SET_CONTACT } from "../constants/actions";

export const getContact = () => {
  const token = localStorage.getItem("ip_access_token");
  return async function (dispatch) {
    try {
      const response = await API.get("contact/detail", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      dispatch({ type: SET_CONTACT, payload: response.data.data });
      return response;
    } catch (err) {
      return err;
    }
  };
};

export const submitContactRequest = (payload) => {
  return async function (dispatch) {
    try {
      const response = await API.post("contact/request", { data: payload });
      return response;
    } catch (e) {
      throw e;
    }
  };
};
