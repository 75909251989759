import { LOG_OUT, SET_PENSIONIERUNG_DATA } from "../constants/actions";
import { Action } from "../actions";
import { PensionierungData } from "../actions/pensionierung";
import { financeExpertBox } from "../constants/mockups";

export interface PensionierungState {
  pensionierungData: PensionierungData;
}

const getInitialState = (): PensionierungState => {
  return {
    pensionierungData: {
      title: "Pensionierung",
      text: "Teilpensionierung? Frühpensionierung? Kapitalbezug oder Rente? Hier finden Sie die Antworten und die Auswirkungen der unterschiedlichen Varianten.",
      retirementBox: {
        amount: 0,
        subTitle: "voraussichtliche Monatsrente in CHF",
        projectedGraph: [],
        link: "",
        linkText: "JETZT SIMULIEREN",
        isVisible: false,
      },
      actionsBox: {
        title: "Welche Optionen stehen mir zur Verfügung?",
        text: "Ihre wichtigsten Entscheide betrefen den ZEitpunkt der Pensionierung und die Frage, ob eine Rente oder das Kapital beziehen möchten. Die Pensionierung ist auch vor oder nach dem ordentlichen Pensionierungsalter möglich. Eine weitere Option ist die Teilpensionierung. Was dabei zu beachten gilt und welche Auswirkungen Ihre Entscheide haben, erfahren Sie im Folgenden.",
        isVisible: false,
        actionList: [],
      },
      simulationBox: {
        title: "Simulation",
        toolTip: "Erklärung zur Simulation als Tooltip",
        text: "Simulieren Sie verschiedene Vorsorgeszenarien und fin den Sie heraus, wie siche dies auf Ihre Finazielle Situation auswirken",
        link: "",
        linkText: "SIMULIEREN STARTEN",
        isVisible: false,
      },
      financeExpertBox,
      purchaseLinkBox: {
        title: "Altersleistungen erhöhen und Steuern sparen",
        text: "Es lohn sich frühzeitig Ihre Pensonierung zu planen. So haben Sie Möglichkeit Ihre Altersleitung zu erhöhen.",
        link: "",
        linkText: "MEHR DAZU",
        isVisible: false,
      },
      contactBox: {
        title: "Kontakt",
        text: "Gerne beraten wir Sie persönlich.",
        link: "",
        linkText: "KONTAKT AUFNEHMEN",
        isVisible: false,
      },
      retirementPageContentBox: {
        content: "",
      },
    },
  };
};

const app = (state: PensionierungState = getInitialState(), action: Action) => {
  switch (action.type) {
    case SET_PENSIONIERUNG_DATA: {
      return { ...state, pensionierungData: action.payload };
    }

    case LOG_OUT: {
      console.log("log out");
      return { ...getInitialState() };
    }

    default: {
      return { ...state };
    }
  }
};

export default app;
