import { Dispatch } from "redux";

import { privateApi } from "../utils/api";
import { SET_IMPERSONATED_USERS } from "../constants/actions";
import refreshToken from "./refreshToken";

export const getImpersonatedUsers = () => {
  return async function (dispatch: Dispatch) {
    try {
      const response = await privateApi.get("impersonation/impersonationspaginated");

      dispatch({ type: SET_IMPERSONATED_USERS, payload: response.data.data });
      return response.data.data;
    } catch (e) {
      throw e;
    }
  };
};

export const postImpersonated = (ssn: string) => {
  return async function () {
    try {
      const response = await privateApi.post(`impersonated/${ssn}?isActive=true`);

      return response.data;
    } catch (e) {
      throw e;
    }
  };
};
