import { CircularLoader } from "../CircularLoader";

import {
  StyledDescription,
  StyledLoaderWrapper,
  StyledTitle,
  StyledWrapper,
} from "./styles";

const InpersonatedLoader = () => {
  return (
    <StyledWrapper>
      <StyledLoaderWrapper>
        <StyledTitle>Benutzeransicht wird geladen</StyledTitle>
        <StyledDescription>
          Bitte warten Sie. Es kann ein paar Sekunden dauern.
        </StyledDescription>
        <CircularLoader />
      </StyledLoaderWrapper>
    </StyledWrapper>
  );
};

export default InpersonatedLoader;
