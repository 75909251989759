import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action } from "../../actions/";
import { getPdf } from "../../actions/document";
import styles from "./PdfViewer.module.scss";
import { SpecialZoomLevel, Viewer, OpenFile } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { getFilePlugin } from "@react-pdf-viewer/get-file";

interface Props {
  token: string;
  link: string;
  getPdf: any;
  onDocumentLoad?: any;
}

const PdfViewer = (props: Props) => {
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file: OpenFile) => {
      const fileName = file.name.substring(file.name.lastIndexOf("/") + 1);
      return `${fileName}.pdf`;
    },
  });
  const { DownloadButton } = getFilePluginInstance;

  const renderToolbar = (Toolbar: (props: any) => any) => (
    <Toolbar>
      {(slots: any) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div style={{ alignItems: "center", display: "flex" }}>
            <div style={{ padding: "0px 2px" }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToPreviousPage />
            </div>
            <div
              style={{
                padding: "0px 2px",
                color: "white",
                alignItems: "center",
                display: "flex",
              }}
            >
              <CurrentPageInput />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToNextPage />
            </div>

            <div
              style={{ alignItems: "center", display: "flex", margin: "auto" }}
            >
              <div style={{ padding: "0px 2px" }}>
                <ZoomOut />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <Zoom />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <ZoomIn />
              </div>
            </div>

            <div style={{ padding: "0px 2px" }}>
              <DownloadButton />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Print />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar,
  });

  const defaultScale =
    window.innerHeight < 1060 ? 1.0 : SpecialZoomLevel.PageFit;

  return (
    <div className={styles.pdfViewerContainer}>
      <Viewer
        theme="dark"
        plugins={[defaultLayoutPluginInstance, getFilePluginInstance]}
        defaultScale={defaultScale}
        onDocumentLoad={(e) => props.onDocumentLoad && props.onDocumentLoad(e)}
        httpHeaders={{ Authorization: "Bearer " + props.token }}
        fileUrl={props.link}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  token: state.app.bearerToken,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getPdf: bindActionCreators(getPdf, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PdfViewer);
